<template>
  <div class="change-info">
    <el-button @click="goBack" type="text">返回上一页</el-button>
    <el-card class="change-info-card">
      <h2 class="change-info-title">修改信息</h2>
      <el-form :model="infoForm" ref="infoForm" label-width="120px">
        <el-form-item label="学号">
          <el-input v-model="infoForm.infoSid" placeholder="请输入学号"></el-input>
        </el-form-item>
        <el-form-item label="手机">
          <el-input v-model="infoForm.infoPhone" placeholder="请输入手机"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select v-model="infoForm.infoSex" placeholder="请选择性别">
            <el-option label="男" value="男"></el-option>
            <el-option label="女" value="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学院">
          <el-input v-model="infoForm.infoCollege" placeholder="请输入学院"></el-input>
        </el-form-item>
        <el-form-item label="专业">
          <el-input v-model="infoForm.infoClass" placeholder="请输入专业"></el-input>
        </el-form-item>
        <el-form-item label="公寓名称">
          <el-input v-model="infoForm.infoDorm" placeholder="请输入公寓名称"></el-input>
        </el-form-item>
        <el-form-item label="宿舍号">
          <el-input type="number" v-model="infoForm.infoDormid" placeholder="请输入宿舍号"></el-input>
        </el-form-item>
        <el-form-item label="家长姓名">
          <el-input v-model="infoForm.infoPname" placeholder="请输入家长姓名"></el-input>
        </el-form-item>
        <el-form-item label="家长联系电话">
          <el-input v-model="infoForm.infoPphone" placeholder="请输入家长联系电话"></el-input>
        </el-form-item>
        <el-form-item label="班主任">
          <el-input v-model="infoForm.infoMname" placeholder="请输入班主任姓名"></el-input>
        </el-form-item>
        <el-form-item label="班主任单位">
          <el-input v-model="infoForm.infoMclass" placeholder="请输入班主任单位"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">修改</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      infoForm: {
        userId: '123',
        infoName: '姓名',
        infoSid: '学号',
        infoPhone: '18212311231',
        infoSex: '男',
        infoCollege: '学院',
        infoClass: '班级',
        infoDorm: '宿舍号',
        infoDormid: null,
        infoPname: null,
        infoPphone: null,
        infoMname: null,
        infoMclass: null
      }
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1); // 返回上一页
    },
    submitForm(message) {
      // 提交表单逻辑

      this.infoForm.userId=this.$route.query.id;
      this.infoForm.infoName=this.$route.query.name;
      console.log('提交的信息：', this.infoForm);
      this.$axios
          .post(this.$httpUrl + "/info/change", this.infoForm)
          .then((res) => res.data)
          .then((res) => {
            if (res.code == 200) {
              //跳转到主页t

              this.$router.push("/Profile");
              this.$message.success('修改成功');
            }
          })
          .catch((e) => {
            alert("修改失败,原因:"+e);
            return false;
          });
      // 这里可以添加实际的提交请求代码
    }
  },
  created() {
    // 在这里可以获取已有信息并填充到表单中
    // 例如：this.infoForm = fetchedInfo;
    let info = sessionStorage.getItem("userInfo");
    try {
      info = info ? JSON.parse(info) : null;
      if (info && typeof info === 'object') {
        this.infoForm = info;
      } else {
        throw new Error('Invalid userInfo format');
      }
    } catch (e) {
      console.error('sessionStorage userInfo parsing error:', e);
      this.infoForm = {
        userId: '',
        infoName: '',
        infoSid: '',
        infoPhone: '',
        infoSex: '',
        infoCollege: '',
        infoClass: '',
        infoDorm: '',
        infoDormid: null,
        infoPname: null,
        infoPphone: null,
        infoMname: null,
        infoMclass: "英语系"};
    }

  }

};
</script>

<style scoped>
.modify-info {
  padding: 20px;
}

.info-card {
  margin-top: 20px;
}

.info-title {
  text-align: center;
  margin-bottom: 20px;
}
</style>
